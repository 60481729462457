import React from 'react';

export const pointPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/points/list',
        component: React.lazy(() => import('./Pages/PointList')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/points/leaderboard',
        component: React.lazy(() => import('./Pages/PointLeaderboard')),
      },
      {
        path: '/points/request',
        component: React.lazy(() => import('./Pages/RequestPoints')),
      },
      {
        path: '/points/breakdown',
        component: React.lazy(() => import('./Pages/PointsBreakdown')),
      },
      {
        path: '/referrals/list',
        component: React.lazy(() => import('./Pages/ReferralList')),
      },
      {
        path: '/referrals/leaderboard',
        component: React.lazy(() => import('./Pages/ReferralsLeaderboard')),
      },
      {
        path: '/meetings/cohost-invites',
        component: React.lazy(() => import('./Pages/CohostInviteList')),
      },
      {
        path: '/meetings/registration',
        component: React.lazy(() => import('../dashboard/MeetingsList')),
      },
      {
        path: '/members/all',
        component: React.lazy(() => import('./Pages/CoopMembers')),
      },
    ],
  },
];
