
import React from 'react';

const year = new Date().getFullYear();

export function useYear() {
  return year;
}

export default function useCopyright(link?: boolean) {
  const year = useYear();

  if (link) {
    return (
      <span>
        &copy; {year} <a href='https://thehappycoop.com/'>The Happy Co-Op</a>. All Rights Reserved.
      </span>
    );
  }

  return `© ${year} The Happy Co-Op. All Rights Reserved.`
}