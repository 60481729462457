
export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'dashboard',
    type: 'item',
    icon: 'dashboard',
    url: '/dashboard'
  },
  {
    id: 'happy-store',
    title: 'Happy Store',
    messageId: 'sidebar.happy-store',
    type: 'item',
    icon: 'dashboard',
    url: '/store'
  },
  // {
  //   id: 'idh',
  //   title: 'IDH 2022',
  //   messageId: 'sidebar.idh',
  //   type: 'collapse',
  //   icon: 'dashboard',
  //   children: [
  //     {
  //       id: 'idh-registration',
  //       title: 'Registration',
  //       messageId: 'sidebar.idh.registration',
  //       type: 'item',
  //       url: '/idh',
  //       exact: true
  //     },
  //     {
  //       id: 'idh-tickets',
  //       title: 'Tickets',
  //       messageId: 'sidebar.idh.tickets',
  //       type: 'item',
  //       url: '/idh/tickets',
  //       exact: true
  //     },
  //     {
  //       id: 'idh-referrals',
  //       title: 'Referrals',
  //       messageId: 'sidebar.idh.referrals',
  //       type: 'item',
  //       url: '/idh/referrals',
  //       exact: true
  //     },
  //     {
  //       id: 'idh-referrals-leaderboard',
  //       title: 'Referrals Leaderboard',
  //       messageId: 'sidebar.idh.referrals.leaderboard',
  //       type: 'item',
  //       url: '/idh/referrals/leaderboard',
  //       exact: true
  //     }
  //   ]
  // },
  {
    id: 'points',
    title: 'Points',
    messageId: 'sidebar.points',
    type: 'collapse',
    icon: 'dashboard',
    children: [
      {
        id: 'point-breakdown',
        title: 'Points Breakdown',
        messageId: 'sidebar.points.breakdown',
        type: 'item',
        url: '/points/breakdown',
      },
      {
        id: 'point-list',
        title: 'Points',
        messageId: 'sidebar.points.list',
        type: 'item',
        url: '/points/list',
      },
      {
        id: 'point-leaderboard',
        title: 'Point Leaderboard',
        messageId: 'sidebar.points.leaderboard',
        type: 'item',
        url: '/points/leaderboard',
      },
      {
        id: 'referrals-list',
        title: 'Referrals',
        messageId: 'sidebar.referrals.list',
        type: 'item',
        url: '/referrals/list',
      },
      {
        id: 'referrals-leaderboard',
        title: 'Referrals Leaderboard',
        messageId: 'sidebar.referrals.leaderboard',
        type: 'item',
        url: '/referrals/leaderboard',
      },
      {
        id: 'point-request',
        title: 'Request Points',
        messageId: 'sidebar.points.request',
        type: 'item',
        url: '/points/request',
      }
    ],
  },
  {
    id: 'vhb',
    title: 'Verified Happy Businesses',
    messageId: 'sidebar.verified-happy-business',
    type: 'collapse',
    icon: 'dashboard',
    children: [
      {
        id: 'listings-list',
        title: 'View Listings',
        messageId: 'sidebar.listings.list',
        type: 'item',
        url: '/listings/all',
      },
      {
        id: 'listings-edit',
        title: 'Edit My Listing',
        messageId: 'sidebar.listings.edit',
        type: 'item',
        url: '/listings/edit',
      },
      {
        id: 'listings-me',
        title: 'View My Listing',
        messageId: 'sidebar.listings.me',
        type: 'item',
        url: '/listings/me',
      },
    ],
  },
  {
    id: 'meetings',
    title: 'Meetings',
    messageId: 'sidebar.meetings',
    type: 'collapse',
    icon: 'dashboard',
    children: [
      // {
      //   id: 'cho-mastermind',
      //   title: 'CHO Mastermind',
      //   messageId: 'sidebar.meetings.cho-mastermind',
      //   type: 'item',
      //   url: '/cho-mastermind',
      // },
      {
        id: 'register',
        title: 'Meeting Detaills',
        messageId: 'sidebar.meetings.registration',
        type: 'item',
        url: '/meetings/registration',
      },
      {
        id: 'cohost-invites',
        title: 'Co-Host Invites',
        messageId: 'sidebar.meetings.cohost-invites',
        type: 'item',
        url: '/meetings/cohost-invites',
      },
    ],
  },
  {
    id: 'member-benefits',
    title: 'Member Benefits',
    messageId: 'member-benefits',
    type: 'item',
    icon: 'dashboard',
    url: '/member-benefits'
  },
  {
    id: 'board-of-directors',
    title: 'Board of Directors',
    messageId: 'board-of-directors',
    type: 'item',
    icon: 'dashboard',
    url: '/board'
  },
  {
    id: 'billing',
    title: 'Billing',
    messageId: 'sidebar.billing',
    type: 'item',
    icon: 'dashboard',
    url: '/billing/subscriptions'
  },
];

export default routesConfig;
