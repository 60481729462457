import jwtAxios, { getCookieDomain } from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
import {defaultUser} from '../../shared/constants/AppConst';
import {AuthUser} from '../../types/models/AuthUser';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../types/actions/Auth.actions';
import Cookies from 'js-cookie';

export const onJwtUserSignUp = (body: {
  email: string;
  password: string;
  name: string;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.post('users', body);
      Cookies.set('token', res.data.token, { domain: getCookieDomain(), expires: 120, secure: true });
      dispatch(setJWTToken(res.data.token));
      await loadJWTUser(dispatch);
    } catch (err: any) {
      console.log('error!!!!', err.response.data.error);
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const onJwtSignIn = (body: {email: string; password: string}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.post('auth', body);
      Cookies.set('token', res.data.token, { domain: getCookieDomain(), expires: 120, secure: true });
      dispatch(setJWTToken(res.data.token));
      await loadJWTUser(dispatch);
    } catch (err: any) {
      console.log('error!!!!', err.response?.data?.error);
      dispatch(fetchError(err.response?.data?.error));
    }
  };
};

export const onRequestMagic = (email: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      await jwtAxios.post('auth/magic', { email });
      dispatch(showMessage('If an account exists with that email, your login link will arrive shortly!'));
    } catch (err: any) {
      console.log('error!!!!', err.response?.data?.error);
      dispatch(fetchError(err.response?.data?.error));
    }
  };
};

export const onMagicSignIn = (token: string, code: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.get('auth/magic', { params: { token, code }});
      Cookies.set('token', res.data.token, { domain: getCookieDomain(), expires: 120, secure: true });
      dispatch(setJWTToken(res.data.token));
      await loadJWTUser(dispatch);
    } catch (err: any) {
      console.log('error!!!!', err.response?.data?.error);
      dispatch(fetchError(err.response?.data?.error));
    }
  };
};

export const loadJWTUser = async (dispatch: Dispatch<AppActions>) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.get('/auth');
    dispatch(fetchSuccess());
    console.log('res.data', res.data);
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: getUserObject(res.data?.member),
    });
  } catch (err: any) {
    console.log('error!!!!', err.response.error);
    dispatch(fetchError(err.response.error));
  }
};

export const setJWTToken = (token: string | null): AppActions => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

const getUserObject = (authUser: any): AuthUser => {
  return {
    authType: AuthType.JWT_AUTH,
    name: authUser.name,
    email: authUser.email,
    role: defaultUser.role,
    token: authUser.uuid,
    uuid: authUser.uuid,
    photoURL: authUser.avatar,
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchSuccess());
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS});
      dispatch(fetchSuccess());
      Cookies.remove('token', { domain: 'happyneighborhoodproject.com' });
    }, 500);
  };
};
