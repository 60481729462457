import {AuthUser} from '../../types/models/AuthUser';
import {AuthType} from './AppEnums';

export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'], // TODO: Osagie, make sure this is right...
};

export const defaultUser: AuthUser = {
  uuid: '00000000-0000-0000-0000-0000000000000',
  name: {
    first: '',
    last: ''
  },
  email: '',
  token: 'access-token',
  role: authRole.user,
  authType: AuthType.AUTH0,
  photoURL: '/assets/images/avatar/A11.jpg',
};

export const initialUrl = '/dashboard'; // this url will open after login
