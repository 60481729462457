import React from 'react';

export const listingPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/listings/all',
        component: React.lazy(() => import('./Pages/Listings')),
      },
      {
        path: '/listings/edit',
        component: React.lazy(() => import('./Pages/EditListing')),
      },
      {
        path: '/listings/me',
        component: React.lazy(() => import('./Pages/Listing')),
      },
      {
        path: '/listings/view/:listing_uuid',
        component: React.lazy(() => import('./Pages/Listing')),
      },
    ],
  },
];
