
import React from 'react';
import { DEBUG_MODE } from '../../@crema/services/auth/jwt-auth/jwt-api';

const DebugView = () => {

  if (DEBUG_MODE) {
    return (
      <div style={{ position: 'fixed', right: '1em', bottom: '1em', zIndex: 1000, opacity: .4, pointerEvents: 'none' }}>
        <h4 style={{ backgroundColor: 'yellow', padding: '.5em', borderRadius: '.5em' }}>Debug Mode!</h4>
      </div>
    );
  }

  return null;
}

export default DebugView;