
import React from 'react';

export const testPageConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/test',
        component: React.lazy(() => import('./TestPage')),
      }
    ]
  }
];

export default testPageConfig;