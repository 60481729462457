
import axios from 'axios';
import Cookies from 'js-cookie';

export const DEBUG_MODE = (
  window.location.hostname === "localhost" || 
  window.location.hostname === "127.0.0.1"
);

export const getCookieDomain = () => DEBUG_MODE ? 
  'localhost' : 
  'happyneighborhoodproject.com';

export const getApiUrl = () => DEBUG_MODE ? 
  'http://localhost:3005/coop/v1/' :
  'https://api.hnpabc.com/coop/v1/';

export const getGraphqlUrl = () => DEBUG_MODE ? 
  'http://localhost:3005/coop/graphql' :
  'https://api.hnpabc.com/coop/graphql/';

const jwtAxios = axios.create({
  baseURL: getApiUrl(),
  headers: {
    'Content-Type': 'application/json',
    'x-auth-token': Cookies.get('token') || localStorage.getItem('token')
  },
});

jwtAxios.interceptors.response.use(
  res => res,
  err => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);

export const setAuthToken = (token: string | null) => {
  if (token) {
    jwtAxios.defaults.headers.common['x-auth-token'] = token;
    Cookies.set('token', token, { domain: getCookieDomain(), expires: 120, secure: true });
  } else {
    delete jwtAxios.defaults.headers.common['x-auth-token'];
    Cookies.remove('token', { domain: getCookieDomain() });
  }
};

export default jwtAxios;
