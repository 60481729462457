
import React from 'react';

export const dashboardPageConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/dashboard',
        component: React.lazy(() => import('./Dashboard')),
      },
      {
        path: '/board',
        component: React.lazy(() => import('./BODMeetingMinutesPage')),
      },
      {
        path: '/stripe',
        component: React.lazy(() => import('./StripeConnect')),
      },
      {
        path: '/member-benefits',
        component: React.lazy(() => import('./MemberBenefits')),
      },
      {
        path: '/store',
        component: React.lazy(() => import('./HappyStore')),
      },
      {
        path: '/cho-mastermind',
        exact: true,
        component: React.lazy(() => import('./ChoMastermind')),
      },
      {
        path: '/ghs',
        exact: true,
        component: React.lazy(() => import('./GHS2022Registration')),
      },
      {
        path: '/ghs/vendor',
        exact: true,
        component: React.lazy(() => import('./GHS2022VendorRegistration')),
      },
      {
        path: '/ghs/tickets',
        exact: true,
        component: React.lazy(() => import('./GHSTicketsPage')),
      },
      {
        path: '/idh',
        exact: true,
        component: React.lazy(() => import('./IDHPage')),
      },
      {
        path: '/idh/register',
        exact: true,
        component: React.lazy(() => import('./IDHRegistration')),
      },
      {
        path: '/idh/vendor',
        exact: true,
        component: React.lazy(() => import('./IDHVendorRegistration')),
      },
      {
        path: '/idh/tickets',
        exact: true,
        component: React.lazy(() => import('./IDHTicketsPage')),
      },
      {
        path: '/idh/bundle',
        exact: true,
        component: React.lazy(() => import('./IDH2023TicketBundle')),
      },
      {
        path: '/idh/referrals',
        exact: true,
        component: React.lazy(() => import('./IDHReferralsPage')),
      },
      {
        path: '/idh/referrals/leaderboard',
        exact: true,
        component: React.lazy(() => import('./IDHReferralsLeaderboardPage')),
      },
      {
        path: '/ghs/tickets/leaderboard',
        exact: true,
        component: React.lazy(() => import('./GHSTicketLeaderboardPage')),
      }
    ]
  }
];

export default dashboardPageConfig;