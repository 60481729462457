
import React from 'react';
import { gql, useQuery } from "@apollo/client";
import { Link } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';


const query = gql`
  query CheckUpgrades {
    coop_profile {
      share
      verified
    }
  }
`;

interface QueryResponse {
  coop_profile : {
    share: boolean;
    verified: boolean;
  }
}

function UpgradeButtons({ mobile }: { mobile?: boolean }) {
  const { data } = useQuery<QueryResponse>(query);

  if (data) {
    const { share, verified } = data.coop_profile;

    if (!share || !verified) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: mobile ? 'column' : 'row' }}>
          { !share && (
            <Box margin={3}>
              <Link 
                component={Button} 
                to='/billing/upgrade/share'>
                  Upgrade to Share Member
              </Link>
            </Box>
          )}
          { !verified && (
            <Box margin={3}>
              <Link 
                component={Button} 
                to='/billing/upgrade/verified'>
                  Upgrade to Verified Happy Business
              </Link>
            </Box>
          )}
        </div>
      );
    }
  }

  return null;
}

export default UpgradeButtons;