import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import AppLayout from '@crema/core/AppLayout';
import AuthRoutes from '@crema/utility/AuthRoutes';
import LocaleProvider from '@crema/utility/LocaleProvider';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import ContextProvider from '@crema/utility/ContextProvider';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

import configureStore, {history} from './redux/store';
import CssBaseline from '@material-ui/core/CssBaseline';
import { getCookieDomain, getGraphqlUrl } from '@crema/services/auth/jwt-auth/jwt-api';
import DebugView from 'modules/errorPages/DebugView';
import Cookies from 'js-cookie';

const store = configureStore();

const httpLink = createHttpLink({
  uri: getGraphqlUrl
});

const authLink = setContext((_, { headers }) => {
  console.log('check token', localStorage.getItem('token'));
  if (localStorage.getItem('token')) {
    console.log('found token');
    const oldToken = localStorage.getItem('token');
    if (!Cookies.get('token') && oldToken) {
      Cookies.set('token', oldToken, { domain: getCookieDomain(), expires: 120, secure: true });
    }

    localStorage.removeItem('token');
  }

  const token = Cookies.get('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  };
});

export const apollo = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const App = () => {
  useEffect(() => {
    console.log('check token', localStorage.getItem('token'));
    if (localStorage.getItem('token')) {
      console.log('found token');
      const oldToken = localStorage.getItem('token');
      if (!Cookies.get('token') && oldToken) {
        Cookies.set('token', oldToken, { domain: getCookieDomain(), expires: 120, secure: true });
      }

      localStorage.removeItem('token');
    }
  }, []);
  
  return (
    <ContextProvider>
      <Provider store={store}>
        <ApolloProvider client={apollo}>
          <CremaThemeProvider>
            <CremaStyleProvider>
              <LocaleProvider>
                <ConnectedRouter history={history}>
                  <AuthRoutes>
                    <CssBaseline />
                    <AppLayout />
                  </AuthRoutes>
                </ConnectedRouter>
              </LocaleProvider>
            </CremaStyleProvider>
          </CremaThemeProvider>
        </ApolloProvider>
      </Provider>
      <DebugView />
    </ContextProvider>
  );
};

export default App;
