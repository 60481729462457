import React from 'react';
import { Redirect } from 'react-router-dom';

import { createRoutes } from '../@crema/utility/Utils';
import dashboardPageConfig from './dashboard';
import { pointPagesConfig } from './points';
import { errorPagesConfigs } from './errorPages';
import { authRouteConfig } from './auth';
import { initialUrl } from '../shared/constants/AppConst';
import { billingPagesConfig } from './billing';
import testPageConfig from './test';
import { listingPagesConfig } from './listings';

const routeConfigs = [
  ...dashboardPageConfig,
  ...pointPagesConfig,
  ...billingPagesConfig,
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...testPageConfig,
  ...listingPagesConfig
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
