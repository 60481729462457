
import React, { useCallback, useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { gql, useQuery } from '@apollo/client';

const pollInterval = 30 * 60 * 1000;

const query = gql`
  query CollectBonus {
    coop_collect_bonus
  }
`;

interface QueryResponse {
  coop_collect_bonus?: string
}

function CollectBonus() {
  const [message, setMessage] = useState('');
  const { data } = useQuery<QueryResponse>(query, {
    pollInterval
  });

  const onClose = useCallback(() => {
    setMessage('');
  }, [setMessage]);

  useEffect(() => {
    if (data?.coop_collect_bonus) {
      setMessage(data.coop_collect_bonus);
    }
  }, [data]);

  return (
  <Snackbar open={Boolean(message)} autoHideDuration={6000} onClose={onClose}>
    <Alert onClose={onClose} severity="success">
      { message }
    </Alert>
  </Snackbar>
  );
}

export default CollectBonus;