
import React from 'react';

export const billingPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/billing/subscriptions',
        component: React.lazy(() => import('./Pages/MySubscriptions')),
      },
      {
        path: '/hvm',
        component: React.lazy(() => import('./Pages/RegisterForHVM')),
      },
      {
        path: '/billing/upgrade/share',
        component: React.lazy(() => import('./Pages/UpgradeToShare')),
      },
      {
        path: '/billing/upgrade/verified',
        component: React.lazy(() => import('./Pages/UpgradeToVerified')),
      },
    ],
  },
];
