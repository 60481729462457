import React from 'react';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {jssPreset, StylesProvider} from '@material-ui/core/styles';
import { Plugin } from 'jss';

// Configure JSS
const jss = create({plugins: [...jssPreset().plugins, rtl()] as Array<Plugin>});

const CremaStyleProvider: React.FC<React.ReactNode> = props => {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
};
export default CremaStyleProvider;
